<template lang="pug">
  v-container(fluid="" tag="section" style='padding:0;')
    v-dialog(v-model='editDctoDialog' persistent='' max-width='290')
      v-card
        v-card-title
          | Actualizar Descuento
        v-card-text
          v-select(:items="disccountTypes" label="Tipo de descuento" v-model='dctoType')
          v-text-field.purple-input(@keyup="calculateTotalPrice" dense :label='dctoType === "%" ? "Descuento %": "Descuento $"' type='number' v-model='dctoVal')
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='cancelEditDcto' :loading="loadingChangeDcto")
            | Cancelar
          v-btn(color='green darken-1' @click='acceptChangeDcto' :loading="loadingChangeDcto")
            | Aceptar
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    .display-2.font-weight-light
      v-row.primary(style='padding-top:10px;')
        v-col(cols='7' sm='7' style='padding: 0px 0px 0px 15px; color:white; display: flex; align-items: center;')
          strong
            | Cuenta total del tratamiento
        v-col(cols='4' sm='4' style='padding: 0px 0px 5px 0px;' v-if='calledFromCuentas === false' v-show='!$vuetify.breakpoint.xs')
          v-btn(@click="goToFinance" style='background: #0E69AA;')
            | EDITAR CUENTAS
        v-col(cols='4' sm='4' v-show='$vuetify.breakpoint.xs' v-if='calledFromCuentas === false')
          v-btn(style='background: #0E69AA;' small dark fab icon='' @click="$emit('exitFinance')" )
            v-icon(medium) mdi-file-document-edit
        v-col(cols='1' sm='1' v-if='calledFromCuentas === false' style='padding: 0px 5px 0px 0px; display:grid; justify-content:end')
          v-btn(dark fab icon='' @click="$emit('exitFinance')")
            v-icon(medium) mdi-close
    v-row(style="height: 36vh")
      v-col(cols="12" md="12" style="padding:0px; overflow: auto" class="elevation-2")
        v-card(v-if='processArray.length === 0 && calledFromCuentas === true')
          p(style='font-weight: bold; font-size: large; padding-left: 12px; text-align: center; background: #c7c7c7;') Presiona CARGAR TRATAMIENTOS y selecciona un tratamiento para ver las cuentas del paciente
        v-card(v-if='processArray.length === 0 && calledFromCuentas === false')
          p(style='font-weight: bold; font-size: large; padding-left: 12px; text-align: center; background: #c7c7c7;') Presiona VER TRATAMIENTOS, selecciona un tratamiento y luego una cita para ver las cuentas del paciente o crea un NUEVO TRATAMIENTO seleccionando el ícono + de color morado
        v-simple-table(style='height: 100px;' scrollable v-if='processArray.length !== 0')
          thead
            tr
              th.primary--text
                | Fecha
              th.primary--text
                | Procedimiento
              th.primary--text
                | Estado
              th.primary--text
                | Total
          tbody
            tr(v-for='(process, index) in processArray' :key='index')
              td
                | {{ process.date }}
              td
                | {{ process.processLabel }}
              td
                | {{ process.processState }}
              td
                | $ {{ process.totalCost }}
    v-row(v-if='showStats===false')
      v-col.payment-section(cols="12" md="3")
        v-row(class="elevation-2")
          v-col(cols="6" style="font-weight: bold;")
            | PAGADO
          v-col(cols="6" style="display:flex; justify-content:flex-end; font-weight: bold;")
            | $ {{ parseFloat(paid).toFixed(2) }}
      v-col.payment-section(cols="12" md="3")
        v-row(class="elevation-2")
          v-col(cols="5" style="font-weight: bold;")
            | SALDO
          v-col(cols="7" style="display:flex; justify-content:flex-end; font-weight: bold;")
            | $ {{ recidueToPay }}
      v-col.payment-section(cols="12" md="3")
        v-row(class="elevation-2")
          v-col(cols="4" style="font-weight: bold;")
            | DCTO
          v-col(cols="6" style="display:flex; justify-content:flex-end; font-weight: bold;")
            | {{ dctoType }} {{ dctoVal ? dctoVal : '0.00' }}
          v-col(cols="2" style="padding: 2px 0px 0px 0px;")
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on='on' icon='' color='blue' @click='editDcto')
                  v-icon
                    | mdi-file-document-edit
              span Editar Descuento
      v-col.payment-section(cols="12" md="3")
        v-row(class="elevation-2")
          v-col(cols="5" style="font-weight: bold;")
            | TOTAL
          v-col(cols="7" style="display:flex; justify-content:flex-end; font-weight: bold;")
            | $ {{totalProcessesPrice}}
    v-row(v-if='showStats===true' class="elevation-2" style="min-height: 150px;")
      v-col(cols="12" md="4" style='padding: 13px 25px 0px 25px; margin:0px;')
          v-select(:items="disccountTypes" label="Tipo de descuento" v-model='dctoType')
          v-text-field.purple-input(@keyup="calculateTotalPrice" dense :label='dctoType === "%" ? "Descuento %": "Descuento $"' type='number' v-model='dctoVal' style='margin-top:10px;')
      v-col(cols="12" md="8")
        v-textarea.purple-input(@keyup="generateDescription" rows="2" label='Descripción del tratamiento' type='text' v-model="descriptionSend" style='padding: 0px 25px 0px 25px; margin:0px;')
        v-row
          v-col(cols="6" style="font-weight: bold; font-size: 20px; padding-top: 25px; justify-content: flex-end; display: flex;")
            | TOTAL:
          v-col(cols="6" style="display:flex; justify-content:flex-start; font-weight: bold;  font-size: 20px; padding-top: 25px;")
            | $ {{totalProcessesPrice}}
</template>
<script>
  var accounting = require('accounting')
  export default {
    components: {
    },
    props: {
      dctoType: {
        type: String,
        default: '$',
      },
      dctoVal: {
        type: String,
        default: '',
      },
      showStats: {
        type: Boolean,
        default: false,
      },
      patientToEdit: {
        type: Object,
        default: null,
      },
      treatmentId: {
        default: null,
        type: String,
      },
      processArray: {
        default: null,
        type: Array,
      },
      paid: {
        default: null,
        type: String,
      },
      calledFromCuentas: {
        default: false,
        type: Boolean,
      },
    },
    data: () => ({
      disccountTypes: ['%', '$'],
      descriptionSend: '',
      loadingChangeDcto: false,
      beforeDcto: null,
      beforeType: null,
      editDctoDialog: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      totalProcessesPrice: null,
      dctoVal: null,
      recidueToPay: null,
    }),
    watch: {
      dctoType () {
        this.calculateTotalPrice()
      },
      processArray () {
        this.reconstructProcessArr()
        this.calculateTotalPrice()
        this.calculateBalanceToPay()
      },
      paid () {
        this.calculateBalanceToPay()
      },
    },
    created () {
      this.reconstructProcessArr()
      this.calculateTotalPrice()
      this.calculateBalanceToPay()
    },
    methods: {
      cancelEditDcto () {
        this.editDctoDialog = false
        this.dctoVal = this.beforeDcto
        this.dctoType = this.beforeType
        this.calculateTotalPrice()
      },
      editDcto () {
        if (this.treatmentId === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Dirígete a VER TRATAMIENTOS y carga un tratamiento para poder editar la cuenta',
          }
        } else {
          // se abre el dialogo para actualizar el valor de descuento
          this.beforeDcto = this.dctoVal
          this.beforeType = this.dctoType
          this.editDctoDialog = true
        }
      },
      acceptChangeDcto () {
        this.loadingChangeDcto = true
        const dctoData = {
          descuento: this.dctoVal,
          disccountType: this.dctoType,
          patientId: this.patientToEdit.id,
          key: this.treatmentId,
        }
        this.$store.dispatch('treatment/editDctoTreatment', dctoData).then(res => {
          if (res) {
            this.$store.dispatch('treatment/editDctoType', dctoData).then(res => {
              if (res) {
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Descuento actualizado correctamente',
                }
                this.editDctoDialog = false
                this.loadingChangeDcto = false
              }
            })
          } else {
            this.loadingChangeDcto = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al actualizar el DESCUENTO',
            }
          }
        }, reject => {
          this.loadingChangeDcto = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al actualizar el DESCUENTO',
          }
        })
      },
      goToFinance () {
        if (this.treatmentId === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Dirígete a VER TRATAMIENTOS y carga un tratamiento para poder editar la cuenta',
          }
        } else {
          this.$router.replace({ name: 'Cuentas', params: { tabNumber: 'tab-2', treatmentKey: this.treatmentId, patientSelected: this.patientToEdit } })
        }
      },
      calculateTotalPrice () {
        this.totalProcessesPrice = 0.00
        let dctoValid = 0
        if (this.dctoVal && this.dctoVal !== '') {
          dctoValid = parseFloat(this.dctoVal)
        }
        for (let i = 0; i < this.processArray.length; i++) {
          // se coloca los dos decimales al valor total del procedimiento en la tabla de cuentas del paciente
          this.processArray[i].totalCost = accounting.toFixed(this.processArray[i].totalCost, 2)
          this.totalProcessesPrice = accounting.toFixed(parseFloat(this.totalProcessesPrice) + parseFloat(this.processArray[i].totalCost), 2)
        }
        if (this.dctoType === '%') {
          this.totalProcessesPrice = accounting.toFixed(parseFloat(this.totalProcessesPrice) - parseFloat(this.totalProcessesPrice) * parseFloat(dctoValid) / 100, 2)
          this.recidueToPay = accounting.toFixed(parseFloat(this.totalProcessesPrice) - parseFloat(this.paid), 2)
        } else {
          this.totalProcessesPrice = accounting.toFixed(parseFloat(this.totalProcessesPrice) - parseFloat(dctoValid), 2)
          this.recidueToPay = accounting.toFixed(parseFloat(this.totalProcessesPrice) - parseFloat(this.paid), 2)
        }
        if (this.totalProcessesPrice < 0) {
          this.dctoVal = this.dctoVal.toString().slice(0, -1)
          this.calculateTotalPrice()
        }
        // si el valor es negativo, se borra el primer caracter (se borra el menos)
        if (dctoValid < 0) {
          this.dctoVal = this.dctoVal.toString().substring(1)
          this.calculateTotalPrice()
        }
        this.$emit('totalDctoToPass', this.dctoVal)
        this.$emit('dctoTypeToPass', this.dctoType)
        this.$emit('totalBillToPass', this.totalProcessesPrice)
      },
      calculateBalanceToPay () {
        this.recidueToPay = accounting.toFixed(parseFloat(this.totalProcessesPrice) - parseFloat(this.paid), 2)
        // se pasa el valor de saldo pendiente al componente Cuentas para que se pueda imprimir en Saldo Pendiente al crear un Recibo
        this.$emit('recidueToPass', this.recidueToPay)
      },
      generateDescription () {
        this.$emit('descriptionToPass', this.descriptionSend)
      },
      reconstructProcessArr () {
        // se reconstruye el array de procedimientos quitando los YA REALIZADOS
        const yaRealizadoIndex = this.processArray.findIndex(process => process.processState === 'Ya Realizado')
        if (yaRealizadoIndex !== -1) {
          this.processArray.splice(yaRealizadoIndex, 1)
        }
      },
    },
  }
</script>
<style scoped>
.payment-section{
  padding:1rem 0px;
}
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  padding-bottom: 0px;
}
</style>
